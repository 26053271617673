/**
 * @license
 *
 * Font Family: Pally
 * Designed by: Indian Type Foundry
 * URL: https://www.fontshare.com/fonts/pally
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Pally Variable(Variable font)
 * Pally Regular
 * Pally Medium
 * Pally Bold
 *
*/

/**
* This is a variable font
* You can controll variable axes as shown below:
* font-variation-settings: 'wght' 700.0;
*
* available axes:

* 'wght' (range from 400.0 to 700.0)

*/

@font-face {
  font-family: 'Pally-var';
  src: url('./Pally-Variable.woff2') format('woff2'),
    url('./Pally-Variable.woff') format('woff'),
    url('./Pally-Variable.ttf') format('truetype');
  font-weight: 400 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Pally';
  src: url('./Pally-Regular.woff2') format('woff2'),
    url('./Pally-Regular.woff') format('woff'),
    url('./Pally-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Pally';
  src: url('./Pally-Medium.woff2') format('woff2'),
    url('./Pally-Medium.woff') format('woff'),
    url('./Pally-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Pally';
  src: url('./Pally-Bold.woff2') format('woff2'),
    url('./Pally-Bold.woff') format('woff'),
    url('./Pally-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
