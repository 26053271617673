@font-face {
  font-family: Pally-var;
  src: url("Pally-Variable.d91959b9.woff2") format("woff2"), url("Pally-Variable.22b1afdb.woff") format("woff"), url("Pally-Variable.927caef2.ttf") format("truetype");
  font-weight: 400 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Pally;
  src: url("Pally-Regular.d212c759.woff2") format("woff2"), url("Pally-Regular.c07175ee.woff") format("woff"), url("Pally-Regular.3696f15b.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Pally;
  src: url("Pally-Medium.1f0bd25f.woff2") format("woff2"), url("Pally-Medium.d01eabf5.woff") format("woff"), url("Pally-Medium.924fa136.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: Pally;
  src: url("Pally-Bold.1e8c32cb.woff2") format("woff2"), url("Pally-Bold.9c7e777b.woff") format("woff"), url("Pally-Bold.34739b12.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

/*# sourceMappingURL=index.12ef6342.css.map */
